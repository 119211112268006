body {
  margin: 0;
  font-family: "Readex Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-geosearch-bar {
  color: #000000;
}

.custom-marker-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.custom-marker-icon.start {
  justify-content: flex-start;
}

.marker-icon {
  width: 22px;
  height: 22px;
  border: 1px solid black;
}

.marker-icon.circle {
  border-radius: 50%;
}


.marker-icon.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  background-color: transparent !important;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: none;
  border-bottom: 26px solid transparent;
  position: relative;
}
.marker-icon.triangle::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -18px;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 28px solid transparent;
  border-bottom-color: black;
  z-index: -1;
}


.marker-icon.red {
  background-color: #DD3533
}
.marker-icon.green {
  background-color: #59e24d;
}
.marker-icon.yellow {
  background-color: #dbb902;
}
.marker-icon.purple {
  background-color: #800080;
}

.marker-icon.triangle.red {
  border-bottom-color: #DD3533;
}

.marker-icon.triangle.yellow {
  border-bottom-color: #dbb902;
}

.marker-icon.triangle.green {
  border-bottom-color: #59e24d;
}
.marker-icon.triangle.purple {
  border-bottom-color: #800080;
}

.marker-icon-label.purple {
  color: white;
}


.marker-icon-label.triangle {
  top: 6px;
}
.marker-icon-label {
  position: absolute;
  color: black;
  font-size: 14px;
  font-weight: bold;
}


.swiper-pagination {
  bottom: -5px;

  .swiper-pagination-bullet {
    background-color: #FFFFFF;
  }

  .swiper-pagination-bullet-active {
    background-color: #dbb902;
  }
}
