.select-popover-paper {
    background-color: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.Toastify__close-button {
    align-self: center;
}
.Toastify__toast-body {
    font: normal 500 14px/16px 'Readex Pro';
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background: #FCE8DB;
    color: #71192F;
}
.Toastify__toast--error .Toastify__close-button {
    color: #71192F;
}
.Toastify__toast--error .Toastify__toast-body {
    color: #71192F;
}
.Toastify__toast--error .Toastify__toast-icon {
    color: #EF665B;
}


.Toastify__toast-theme--colored.Toastify__toast--success {
    background: #EDFBD8;
    color: #2B641E;
}
.Toastify__toast--success .Toastify__close-button {
    color: #2B641E;
}
.Toastify__toast--success .Toastify__toast-body {
    color: #2B641E;
}
.Toastify__toast--success .Toastify__toast-icon {
    color: #84D65A;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #FEF7D1;
    color: #755118;
}
.Toastify__toast--warning .Toastify__close-button {
    color: #755118;
}
.Toastify__toast--warning .Toastify__toast-body {
    color: #755118;
}
.Toastify__toast--warning .Toastify__toast-icon {
    color: #F7C752;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    background: #D7F1FD;
    color: #F7C752;
}
.Toastify__toast--info .Toastify__close-button {
    color: #0C2A75;
}
.Toastify__toast--info .Toastify__toast-body {
    color: #0C2A75;
}
.Toastify__toast--info .Toastify__toast-icon {
    color: #509AF8;
}


body {
    scrollbar-width: thin;
    background-color: #000000 !important;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background: #FFF75C;
    border-radius: 15px;
}


.select-popover-paper .MuiList-root {
    background-color: #000000;
    padding: 0;
}

.map {
    width: 100%;
    height: 100%;
}

.MuiMultiSectionDigitalClock-root .MuiList-root::after {
    display: none;
}

.MuiPopover-paper {
    border-radius: 15px !important;
    border: 1px solid #dbb902;
}

